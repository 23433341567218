import React from 'react'
import '../style/service.css'
import logo from '../images/b.png'
import img1 from '../images/4720378.png'
import img2 from '../images/1.png'
import img3 from '../images/s.png'
import img4 from '../images/r.png'
import img5 from '../images/d.png'
import img6 from '../images/e.png'
import img7 from '../images/g.png'
import img8 from '../images/a.png'
import img9 from '../images/st.png'
import img10 from '../images/cs.svg'
import img11 from '../images/payroll.png'

const Service = () => {
    return (
        <>
            <div className="service2-section-areas section-padding8">
                <img src="assets/images/elementor/elementor9.png" alt="" className="elementoe5" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-haeder-text text-center margin-b60">
                                <span className="maximum font-outfit font-16 lineh-16 color-12 weight-500 margin-b20 d-inline-block">You’re Goals In Our Way</span>
                                <h1 className="font-outfit font-44 weight-600 lineh-52 color-17 margin-b20" style={{ textShadow: '4px 4px 5px rgba(0, 0, 0, 0.3)', color: '#196C68' }}>Your Financial Clarity Is Our Top Priority</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-lg-4 col-md-6">
                            <div className="service-after-before">
                                <div className="service2-icon">
                                    <img src={img1} width={'38px'} height={'38px'} alt="Outsourcing" />
                                </div>
                                <h3 className="font-outfit font-22 weight-600 lineh-22 color-17 margin-b">Outsourcing</h3>
                                <ul className='listStyle'>
                                    <li>Only pay for what you want</li>
                                    <li>Professional help in peak seasons</li>
                                    <li>Get and cancel add-on services anytime</li>
                                    <li>Real-time access to reports and information</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="service-after-before">
                                <div className="service2-icon">
                                    <img src={logo} width={'38px'} height={'38px'} alt="Bookkeeping" />
                                </div>
                                <h3 className="font-outfit font-22 weight-600 lineh-22 color-17 margin-b">Bookkeeping</h3>
                                <ul className='listStyle'>
                                    <li>Keeping detailed transactional records</li>
                                    <li>AI-based recording of cash flow</li>
                                    <li>Error-free recording and maintenance</li>
                                    <li>3 way checking for transactions</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="service-after-before">
                                <div className="service2-icon">
                                    <img src={img2} width={'38px'} height={'38px'} alt="Financial Forecasting" />
                                </div>
                                <h3 className="font-outfit font-22 weight-600 lineh-22 color-17 margin-b">Financial Forecasting</h3>
                                <ul className='listStyle'>
                                    <li>Manage resources effectively to save more</li>
                                    <li>Proactive approach with Cad Bookkeeping</li>
                                    <li>Advanced based environmental data analysis</li>
                                    <li>Years of experience as strategies</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="service-after-before">
                                <div className="service2-icon">
                                    <img src={img3} width={'38px'} height={'38px'} alt="Virtual CFO Solutions" />
                                </div>
                                <h3 className="font-outfit font-22 weight-600 lineh-22 color-17 margin-b">Virtual CFO Solutions</h3>
                                <ul className='listStyle'>
                                    <li>Expert advice whenever you want</li>
                                    <li>Special discounts with Cad Bookkeeping</li>
                                    <li>Save more by not hiring a full-time CFO</li>
                                    <li>Get real-time data for Canadian markets</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="service-after-before">
                                <div className="service2-icon">
                                    <img src={img4} width={'38px'} height={'38px'} alt="Virtual Accountants" />
                                </div>
                                <h3 className="font-outfit font-22 weight-600 lineh-22 color-17 margin-b">Virtual Accountants</h3>
                                <ul className='listStyle'>
                                    <li>Experienced team for better categorisation</li>
                                    <li>More savings by reducing overhead cost</li>
                                    <li>Get personalised solutions for complex problems</li>
                                    <li>Get helping hands at year-end</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="service-after-before">
                                <div className="service2-icon">
                                    <img src={img5} width={'38px'} height={'38px'} alt="Compliance Management" />
                                </div>
                                <h3 className="font-outfit font-22 weight-600 lineh-22 color-17 margin-b">Compliance Management</h3>
                                <ul className='listStyle'>
                                    <li>Adherence to local and international laws</li>
                                    <li>Complies with industry norms and standards</li>
                                    <li>The global alliance ensures updated laws</li>
                                    <li>Approved document formats and procedures</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="service-after-before">
                                <div className="service2-icon">
                                    <img src={img6} width={'38px'} height={'38px'} alt="Payroll Management" />
                                </div>
                                <h3 className="font-outfit font-22 weight-600 lineh-22 color-17 margin-b">Payroll Management</h3>
                                <ul className='listStyle'>
                                    <li>Get direct pay in the accounts</li>
                                    <li>Personalised premium portals for data access</li>
                                    <li>Separate calculation for employees in branches</li>
                                    <li>Record maintenance for audit</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="service-after-before">
                                <div className="service2-icon">
                                    <img src={img7} width={'38px'} height={'38px'} alt="Data Processing" />
                                </div>
                                <h3 className="font-outfit font-22 weight-600 lineh-22 color-17 margin-b">Data Processing</h3>
                                <ul className='listStyle'>
                                    <li>Automated data collection protocols</li>
                                    <li>Data analysis and reporting</li>
                                    <li>Personalised reporting for decision-making</li>
                                    <li>Flexible data extraction for operational needs</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="service-after-before">
                                <div className="service2-icon">
                                    <img src={img9} width={'38px'} height={'38px'} alt="Taxation Services" />
                                </div>
                                <h3 className="font-outfit font-22 weight-600 lineh-22 color-17 margin-b">Taxation Services</h3>
                                <ul className='listStyle'>
                                    <li>Manage employment taxes for corporations</li>
                                    <li>Calculation and filing of tax reductions</li>
                                    <li>Tax management via global alliances</li>
                                    <li>Internal tax audits for quality needs</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="service-after-before">
                                <div className="service2-icon">
                                    <img src={img10} width={'38px'} height={'38px'} alt="Audits" />
                                </div>
                                <h3 className="font-outfit font-22 weight-600 lineh-22 color-17 margin-b">Audits</h3>
                                <ul className='listStyle'>
                                    <li>Global audits for efficient cash flow</li>
                                    <li>Certified financial officers on team</li>
                                    <li>Advanced techniques for reducing audit</li>
                                    <li>Get a proper audit in different tax sessions</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="service-after-before">
                                <div className="service2-icon">
                                    <img src={img8} width={'38px'} height={'38px'} alt="Payroll Management" />
                                </div>
                                <h3 className="font-outfit font-22 weight-600 lineh-22 color-17 margin-b">GST/HST</h3>
                                <ul className='listStyle'>
                                    <li>One-stop solution for all taxation hurdles</li>
                                    <li>Tax calculations for various provincial operations</li>
                                    <li>Professional CPAs and CAs at your service</li>
                                    <li>Get saving strategies from day 1</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="service-after-before">
                                <div className="service2-icon">
                                    <img src={img11} width={'38px'} height={'38px'} alt="Corporate Training" />
                                </div>
                                <h3 className="font-outfit font-22 weight-600 lineh-22 color-17 margin-b">Corporate Training</h3>
                                <ul className='listStyle'>
                                    <li>Training for better financial clarity</li>
                                    <li>Certified tutors for high-quality education</li>
                                    <li>Get personalised classes for corporate needs</li>
                                    <li>Seminars for handling complex situations</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Service
